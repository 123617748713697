<template>
    <div ref="progressCircle" class="progress-circle" :data-content="content ?? `${Math.round(progress)}%`"></div>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from "vue";

const progressCircle = ref(null);

const props = defineProps({
    // percent of progress
    progress: {
        type: Number,
        default: 0,
    },
    content: {
        type: String,
        default: undefined,
    },
});

const updateProgress = (progress) => progressCircle.value?.style.setProperty("--progress", `${Math.round(progress || props.progress)}%`);

onMounted(updateProgress);
watch(() => props.progress, updateProgress);
</script>

<style scoped>
div.progress-circle {
    @apply w-full h-full rounded-full flex;
    transition: all 0.3s;
    background: conic-gradient(#2ab756 var(--progress), #b7eb8f 0deg);
}

div.progress-circle::after {
    @apply text-xs font-semibold flex flex-col justify-center w-full rounded-full bg-white text-center;
    content: attr(data-content);
    margin: 5px;
}
</style>
