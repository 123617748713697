<template>
    <HomeSectionContainer v-if="shouldShowChecklist" title="Get to know Nadia" class="!bg-white">
        <template #title="{ title }">
            <div class="grow flex items-center justify-between mb-3 gap-4">
                <h3 class="md:text-2xl font-semibold tracking-tight">{{ title }}</h3>
                <span class="h-10 w-10">
                    <ProgressCircle :progress="progress.percent" :content="progress.content" />
                </span>
            </div>
        </template>
        <div class="flex gap-4 flex-nowrap overflow-x-auto">
            <div
                v-for="{ key, iconClass, title } in checklist"
                :key="key"
                class="relative rounded-lg p-3 border-2 border-[#E8E8E8] min-w-40 cursor-pointer"
                :class="{ '!border-[#B7EB8F] !bg-[#F6FFED] !text-[#2AB756]': newUserChecklistItems[key]?.is_done }"
                @click="() => onChecklistItemClick(key)"
            >
                <i v-if="newUserChecklistItems[key]?.is_done" class="absolute bi bi-check text-3xl top-0 right-2"></i>
                <div class="flex flex-col justify-between h-full">
                    <template v-if="key === 'organization' && organizationPromptTitle">
                        <img v-if="organizationIconSrc" :src="organizationIconSrc" alt="Organization icon" class="max-h-8 w-fit mb-2" />
                        <span class="font-semibold">Try a {{ organizationPromptTitle }}</span>
                    </template>
                    <template v-else>
                        <i :class="iconClass" class="text-2xl"></i>
                        <span class="font-semibold">{{ title }}</span>
                    </template>
                </div>
            </div>
        </div>
    </HomeSectionContainer>
</template>

<script setup>
import ProgressCircle from "~vue/components/ProgressCircle.vue";
import { openUrl } from "~vue/utils";
import { DateTime } from "luxon";
import { computed } from "vue";

import HomeSectionContainer from "./HomeSectionContainer.vue";
const firstWeekChecklist = [
    { key: "chat", iconClass: "bi bi-chat", title: "Have your first chat" },
    { key: "mic", iconClass: "bi bi-mic", title: "Try using your mic" },
    { key: "schedule", iconClass: "bi bi-calendar", title: "Schedule your next check-in" },
    { key: "role-play", iconClass: "bi bi-asterisk", title: "Try a role-play with Nadia" },
    { key: "upload", iconClass: "bi bi-backpack3", title: "Upload context in chat" },
];

const firstMonthChecklist = [
    { key: "schedule", iconClass: "bi bi-calendar", title: "Schedule your next check-in" },
    { key: "insight", iconClass: "bi bi-lightning-charge", title: "Follow up on a Nadia insight" },
    { key: "organization", iconClass: "", title: "" }, // icon and title is custom for organization prompts
    { key: "role-play", iconClass: "bi bi-asterisk", title: "Try a role-play with Nadia" },
    { key: "upload", iconClass: "bi bi-backpack3", title: "Upload context in chat" },
];

const emit = defineEmits(["schedule"]);

const { userCreatedAt, newUserChecklistItems, organizationPromptTitle, organizationIconSrc, defaultChatUrl } = defineProps({
    newUserChecklistItems: {
        type: Object,
        default: () => ({
            chat: { is_done: false },
            mic: { is_done: false },
            schedule: { is_done: false },
            "role-play": { is_done: false },
            upload: { is_done: false },
            organization: { is_done: false },
            insight: { is_done: false },
        }),
    },
    userCreatedAt: { type: String, default: undefined },
    organizationPromptTitle: { type: String, default: undefined },
    organizationPromptUrl: { type: String, default: undefined },
    organizationIconSrc: { type: String, default: undefined },
    defaultChatUrl: { type: String, default: undefined },
    insightChatUrl: { type: String, default: undefined },
});

const userCreatedAtDate = computed(() => DateTime.fromISO(userCreatedAt));
const isUserFirstWeek = computed(() => {
    return userCreatedAtDate.value.isValid && userCreatedAtDate.value.plus({ weeks: 1 }) > DateTime.now();
});
const isUserFirstMonth = computed(() => {
    return userCreatedAtDate.value.isValid && userCreatedAtDate.value.plus({ months: 1 }) > DateTime.now();
});

const checklist = computed(() => (isUserFirstWeek.value ? firstWeekChecklist : firstMonthChecklist));

const progress = computed(() => {
    const numCompleted = checklist.value.filter(({ key }) => newUserChecklistItems[key]?.is_done).length;
    const total = checklist.value.length;
    return {
        percent: (numCompleted / total) * 100,
        content: `${numCompleted}/${total}`,
    };
});

const shouldShowChecklist = computed(() => progress.value.percent < 100 && (isUserFirstWeek.value || isUserFirstMonth.value));

const onChecklistItemClick = (key) => {
    switch (key) {
        case "chat":
        case "mic":
        case "role-play":
        case "upload":
        case "organization":
        case "insight":
            if (newUserChecklistItems[key]?.start_url) {
                openUrl(newUserChecklistItems[key].start_url);
            } else {
                openUrl(defaultChatUrl);
            }
            break;
        case "schedule":
            // opens calendar scheduling dialog in ChatHome.vue
            emit("schedule");
            break;
        default:
            break;
    }
};
</script>

<style scoped></style>
