<template>
    <div class="flex flex-col lg:flex-row gap-4 h-full w-full">
        <div class="flex-grow flex flex-col justify-center">
            <div class="aspect-square w-full max-w-[16rem] mx-auto">
                <canvas ref="chartCanvas"></canvas>
            </div>
        </div>
        <div class="flex items-center">
            <div :class="legendGridClass">
                <div v-for="(value, key, index) in chartData" :key="key" class="flex items-center text-left" :class="{ 'flex-nowrap': isEmptyState }">
                    <span
                        class="inline-block w-4 h-4 sm:w-5 sm:h-5 min-w-[8px] min-h-[8px] mr-0.5 rounded-full flex-shrink-0 border-2"
                        :style="{ backgroundColor: getColor(index), borderColor: getBorderColor(index) }"
                    ></span>
                    <span class="capitalize text-xs sm:text-sm ml-1 font-medium text-[#8C8C8C]">{{ key }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Chart from "chart.js/auto";
import { computed, onMounted, onUnmounted, ref, watchEffect } from "vue";

const EMPTY_STATE_DATA = { "Focus 1": 1, "Focus 2": 0.2, "Focus 3": 0.4, "Focus 4": 0.8 };

const { data, isEmptyState } = defineProps({
    data: { type: Object, required: true },
    isEmptyState: { type: Boolean, default: false },
});

let chart = null;
const chartCanvas = ref(null);
const chartData = ref(data);

watchEffect(() => {
    chartData.value = isEmptyState ? EMPTY_STATE_DATA : data;
    if (chart) {
        createChart();
    }
});

const getPositionDataset = (categories) => {
    const categoryCount = Object.keys(categories).length;
    switch (categoryCount) {
        case 1:
            return [{ x: 0, y: 0 }];
        case 2:
            return [
                { x: -0.4, y: 0.4 },
                { x: 0.4, y: -0.4 },
            ];
        case 3:
            return [
                { x: -0.4, y: 0.4 },
                { x: 0.5, y: -0.5 },
                { x: 0.5, y: 0.6 },
            ];
        case 4:
            return [
                { x: -0.5, y: 0.4 },
                { x: 0.4, y: -0.2 },
                { x: -0.3, y: -0.5 },
                { x: 0.4, y: 0.6 },
            ];
        default:
            return [];
    }
};

const legendGridClass = computed(() => {
    const categoryCount = Object.keys(chartData.value).length;
    const baseClasses = "gap-x-4 gap-y-2 text-[8px] leading-none mx-auto";

    if (categoryCount === 1) {
        return `grid grid-cols-1 ${baseClasses}`;
    } else {
        return "flex flex-col gap-2";
    }
});

const createChart = () => {
    if (chart) {
        chart.destroy();
    }

    const ctx = chartCanvas.value.getContext("2d");
    const positions = getPositionDataset(chartData.value);

    const datasets = Object.entries(chartData.value).map(([key, value], index) => ({
        label: key,
        data: [{ ...positions[index], r: Math.log(value + 1.05) * 80 }],
        backgroundColor: getColor(index),
        borderColor: getBorderColor(index),
        borderWidth: 4,
    }));

    chart = new Chart(ctx, {
        type: "bubble",
        data: { datasets },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                autoPadding: false,
            },
            scales: {
                x: { display: false, min: -1, max: 1 },
                y: { display: false, min: -1, max: 1 },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: !isEmptyState,
                    backgroundColor: "rgba(45,52,63,1)",
                    padding: 10,
                    callbacks: {
                        label: function (context) {
                            return context.dataset.label.charAt(0).toUpperCase() + context.dataset.label.slice(1);
                        },
                    },
                },
            },
            elements: {
                point: {
                    hoverRadius: 0,
                },
            },
        },
    });
};

const colors = [
    { backgroundColor: "#F0F5FF", borderColor: "#B8C9FF" }, // Brand Blue
    { backgroundColor: "#FFFBE6", borderColor: "#FFC53D" }, // Orange
    { backgroundColor: "#FFF0F6", borderColor: "#FFADD2" }, // Pink
    { backgroundColor: "#F6FFED", borderColor: "#73D13D" }, // Green
    { backgroundColor: "#E8E8E8", borderColor: "#F2F2F7" }, // Light Gray
];
const emptyStateColors = { backgroundColor: "#F5F5F5", borderColor: "#BFBFBF" };

const getColor = (index) => (isEmptyState ? emptyStateColors.backgroundColor : colors[index % colors.length].backgroundColor);
const getBorderColor = (index) => (isEmptyState ? emptyStateColors.borderColor : colors[index % colors.length].borderColor);

onMounted(() => {
    createChart();
});

onUnmounted(() => {
    if (chart) {
        chart.destroy();
    }
});
</script>
