<template>
    <div class="rounded-3xl p-6 md:p-10 flex flex-col items-center gap-6 border-2 border-[#E8E8E8] bg-[linear-gradient(180deg,#FFF_0%,#FDF6FF_100%)]">
        <h3 class="md:text-2xl font-semibold tracking-tight">Today's reflection</h3>
        <div class="grow w-full flex flex-col justify-center gap-2">
            <Questions
                :current-question="{
                    text: questionText,
                    answer: null,
                    llmResponse: null,
                    offTopic: false,
                }"
                :disabled="isSaving"
                :current-question-position="1"
                :questions-count="1"
                :hide-steps="true"
                :is-custom-content="isAnswered"
                :is-voice-enabled="!isInIFrame()"
                :is-question-position-shown="false"
                @answer="onAnswer"
                @recording-level="onRecordingLevel"
                @recording-start="onRecordingStart"
                @recording-stop="onRecordingStop"
                @transcribe-start="onTranscribeStart"
                @transcribe-end="onTranscribeEnd"
                @trascribe-error="onTranscribeError"
                @using-text="onUsingText"
            >
                <!-- custom content is used to display the daily lock screen when question is answered -->
                <template #custom-content>
                    <div class="grow flex flex-col items-center">
                        <div class="bg-white rounded-full h-[42px] w-[42px] flex items-center justify-center">
                            <Lock />
                        </div>
                        Come back tomorrow for your next check-in
                    </div>
                </template>
            </Questions>
            <div v-if="hasError" class="text-error bg-white/50 py-2 px-4 mt-2 rounded-lg">{{ ERROR_MESSAGE }}</div>
        </div>
    </div>
</template>

<script setup>
import Lock from "~vue/icons/Lock";
import Questions from "~vue/Onboarding/Questions.vue";
import { isInIFrame } from "~vue/utils";
import { logUserInteraction } from "~vue/utils/logUtils.js";
import { defineProps, onMounted, ref, watch } from "vue";

import { getCookie } from "../../utils.js";

const ERROR_MESSAGE = "Oops! I was having trouble hearing you. Make sure your mic is turned on. If the problem persists, please try again later.";

const props = defineProps({
    dailyQuestion: {
        type: Object,
        required: true,
    },
    isDemo: {
        type: Boolean,
        default: false,
    },
});

const questionId = ref(props.dailyQuestion?.id);
const questionText = ref(props.dailyQuestion?.text);

const isAnswered = ref(
    !props.isDemo && localStorage.getItem("dailyQuestion.answered") && localStorage.getItem("dailyQuestion.answered") === String(new Date().setHours(0, 0, 0, 0)),
);
const isSaving = ref(false);
const hasError = ref(false);

function onRecordingStart() {
    logUserInteraction("user_recording_start_daily_question");
}

function onRecordingStop() {
    logUserInteraction("user_recording_ended");
}

function onTranscribeStart() {
    logUserInteraction("transcription_request_start");
}

function onTranscribeEnd() {
    logUserInteraction("transcription_request_succeeded");
    logUserInteraction("transcription_ended");
}

function onTranscribeError() {
    logUserInteraction("transcription_request_failed");
}

function onUsingText() {
    logUserInteraction("daily_question_using_text");
}

async function onAnswer({ answer }) {
    isSaving.value = true;
    try {
        const response = await fetch("/api/chat/coach/daily-question", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
                question: questionId.value,
                answer: answer,
            }),
        });
        logUserInteraction("daily_question_answer_submitted", { question_id: questionId.value });
        if (!response.ok) {
            logUserInteraction("daily_question_answer_failed_to_save", { question_id: questionId.value, response_status: response.status });
            console.error("Failed to save daily question answer", response);
            throw new Error(`Failed to save daily question answer.  Status: ${response.status}`);
        }

        const responseBody = await response.json();
        logUserInteraction("daily_question_answer_saved", { question_id: questionId.value, answer_id: responseBody.answer.id });
        questionId.value = responseBody.nextQuestion.id;
        questionText.value = responseBody.nextQuestion.text;
        isAnswered.value = !props.isDemo;
        localStorage.setItem("dailyQuestion.answered", new Date().setHours(0, 0, 0, 0));
    } catch (error) {
        hasError.value = true;
        if ("Sentry" in window) {
            window.Sentry.captureException(new Error("Failed to save daily question answer", { cause: err }));
        }
    } finally {
        isSaving.value = false;
    }
}

onMounted(() => {
    logUserInteraction("saw_daily_question", { question_id: questionId.value });
});

watch(questionId, (newQuestionId) => {
    logUserInteraction("saw_daily_question", { question_id: newQuestionId });
});

watch(hasError, (newHasError) => {
    if (newHasError) {
        logUserInteraction("saw_daily_question_error_status", { question_id: questionId.value });
    }
});
</script>
