<template>
    <div v-if="_showBanner && !!notificationMessage" class="rounded-xl py-4 px-6 bg-[#FFF7E6] border-2 border-[#FFE58F] flex justify-between items-center">
        <!-- eslint-disable-next-line vue/no-v-html - sanitized in computed renderableMessage -->
        <MarkdownText class="text-sm text-[#d48806] font-semibold" :text="notificationMessage" />
        <i class="bi bi-x cursor-pointer text-2xl text-[#d48806]" @click="dismiss"></i>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

import MarkdownText from "./MarkdownText.vue";

const notificationMessage = ref("");
const _notificationName = ref("");
const _showBanner = ref(false);

const getGlobalNotification = async () => {
    const response = await fetch("/admin/global-notifications/", {
        method: "GET",
        credentials: "include",
    });

    if (!response.ok) {
        throw new Error(`Error fetching GlobalNotifications: ${response.status}`);
    }

    return await response.json();
};

const dismiss = () => {
    _showBanner.value = false;
    localStorage.setItem(`globalNotificationDismissed ${_notificationName.value}`, true);
};

onMounted(async () => {
    const response = await getGlobalNotification();

    if (!response || response.length === 0) {
        _showBanner.value = false;
        return;
    }

    notificationMessage.value = response[0].message;
    _notificationName.value = response[0].name;
    _showBanner.value = !localStorage.getItem(`globalNotificationDismissed ${_notificationName.value}`);
});
</script>
