<template>
    <HomeSectionContainer v-if="organizationName && organizationPrompts.length > 0" :title="sectionTitle" class="!bg-white !border-[rgba(152,0,46,0.24)]">
        <template #title="{ title }">
            <div class="flex items-center justify-between mb-3 gap-2">
                <h3 class="md:text-2xl font-semibold tracking-tight">{{ title }}</h3>
                <img v-if="organizationIconImgSrc" :src="organizationIconImgSrc" :alt="`${organizationName} icon`" class="h-8" />
            </div>
        </template>
        <div class="flex flex-nowrap overflow-x-auto gap-3">
            <div v-if="organizationImgSrc" class="flex items-center">
                <img :src="organizationImgSrc" :alt="`${organizationName} image`" class="max-w-64" />
            </div>
            <HomeTrendingOrgCard v-for="{ external_name, start_url } of organizationPrompts" :key="external_name" :title="external_name" :url="start_url" class="min-w-64" />
        </div>
    </HomeSectionContainer>
</template>

<script setup>
import HomeSectionContainer from "~vue/ChatHome/HomeSectionContainer.vue";
import HomeTrendingOrgCard from "~vue/ChatHome/HomeTrendingOrgCard.vue";
import { computed } from "vue";

const { organizationName, organizationPrompts, organizationIconImgSrc, organizationImgSrc, isDefaultOrganization } = defineProps({
    organizationName: {
        type: String,
        default: undefined,
    },
    organizationPrompts: {
        type: Array,
        default: () => [],
    },
    organizationImgSrc: {
        type: String,
        default: undefined,
    },
    organizationIconImgSrc: {
        type: String,
        default: undefined,
    },
    isDefaultOrganization: {
        type: Boolean,
        default: false,
    },
});

const sectionTitle = computed(() => {
    const orgName = !organizationName || isDefaultOrganization ? "your organization" : organizationName;
    return `Trending at ${orgName}`;
});
</script>
