<template>
    <HomeCard class="!min-w-[300px] !max-w-[320px]" :show-arrow="showArrow" @click="handleClick">
        <div class="flex flex-col justify-between gap-6">
            <div class="flex flex-col gap-2" :class="{ grow: !content }">
                <slot name="header">
                    <i class="bi bi-lightning-charge text-2xl"></i>
                </slot>
                <span class="md:text-2xl font-semibold tracking-tighter">{{ title }}</span>
            </div>
            <slot name="content">
                <span v-if="!!content" class="text-valence-grey-400 font-medium">{{ formattedContent }}</span>
            </slot>
        </div>
    </HomeCard>
</template>

<script setup>
import { openUrl } from "~vue/utils";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed } from "vue";

import HomeCard from "./HomeCard.vue";

const MAX_CONTENT_CHAR_LENGTH = 150;

const { title, url, content } = defineProps({
    title: {
        type: String,
        default: "",
    },
    url: {
        type: String,
        default: "",
    },
    content: {
        type: String,
        default: "",
    },
    showArrow: {
        type: Boolean,
        default: true,
    },
});

const formattedContent = computed(() => {
    if (content.length < MAX_CONTENT_CHAR_LENGTH) {
        return content;
    }
    const contentSplitByWords = content.split(" ");

    return (
        contentSplitByWords.reduce((acc, word) => {
            if (acc.length + word.length > MAX_CONTENT_CHAR_LENGTH) {
                return acc;
            }
            return `${acc} ${word}`;
        }, "") + "..."
    );
});

const handleClick = () => {
    logUserInteraction("saved_insights_clicked", { title: title });
    if (url) {
        openUrl(url);
    }
};
</script>

<style scoped>
.iconBold::before {
    /* overwrites bootstrap icon font weight */
    font-weight: bold !important;
}
</style>
