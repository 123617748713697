<template>
    <HomeSectionContainer :title="insightTitle" class="!border-none !p-0 relative">
        <div class="flex flex-nowrap overflow-x-auto gap-3">
            <template v-if="insightCount > 0">
                <HomeInsightCard v-for="{ id, text, chat_url } of userInsights.slice(0, 3)" :key="id" :content="text" :url="chat_url" />
            </template>
            <template v-else>
                <!-- empty state -->
                <HomeInsightCard title="Unlock your insights" :url="defaultChatUrl" :show-arrow="false">
                    <template #header>
                        <CoachingModeMarble class="w-8 h-8" />
                    </template>
                    <template #content>
                        <!-- top-2 aligns with the arrow from side by side cards -->
                        <BaseButton theme="startChat" class="w-fit flex items-center top-2">
                            Start Chat
                            <span class="rounded-full flex items-center justify-center p-4 w-5 h-5 bg-slate-300 self-end">
                                <i class="bi bi-arrow-right text-lg text-[#555BA2] iconBold"></i> </span
                        ></BaseButton>
                    </template>
                </HomeInsightCard>
                <HomeInsightCard title="A potential blindspot" :content="text" :url="defaultChatUrl">
                    <template #header>
                        <i class="bi bi-lock text-2xl"></i>
                    </template>
                    <template #content>
                        <!-- TODO: copy pasted skeleton below for now, this is another one for design system -->
                        <div class="flex flex-col gap-1">
                            <div class="h-2 w-full rounded-full bg-[#E8E8E8]"></div>
                            <div class="h-2 w-full rounded-full bg-[#E8E8E8]"></div>
                            <div class="h-2 w-full rounded-full bg-[#E8E8E8]"></div>
                            <div class="h-2 w-3/5 rounded-full bg-[#E8E8E8]"></div>
                        </div>
                    </template>
                </HomeInsightCard>
                <HomeInsightCard title="Tacitical follow-up" :content="text" :url="defaultChatUrl">
                    <template #header>
                        <i class="bi bi-lock text-2xl"></i>
                    </template>
                    <template #content>
                        <div class="flex flex-col gap-1">
                            <div class="h-2 w-full rounded-full bg-[#E8E8E8]"></div>
                            <div class="h-2 w-full rounded-full bg-[#E8E8E8]"></div>
                            <div class="h-2 w-full rounded-full bg-[#E8E8E8]"></div>
                            <div class="h-2 w-3/5 rounded-full bg-[#E8E8E8]"></div>
                        </div>
                    </template>
                </HomeInsightCard>
            </template>
        </div>
    </HomeSectionContainer>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import { computed } from "vue";

import HomeInsightCard from "./HomeInsightCard.vue";
import HomeSectionContainer from "./HomeSectionContainer.vue";

const { userInsights, defaultChatUrl } = defineProps({
    userInsights: {
        type: Array,
        required: true,
    },
    defaultChatUrl: {
        type: String,
        required: true,
    },
});

// convert userInsights props into
const insightCount = computed(() => userInsights.length);
const insightTitle = "Things I've noticed about you";
</script>

<style scoped>
.iconBold::before {
    /* overwrites bootstrap icon font weight */
    font-weight: bold !important;
}
</style>
