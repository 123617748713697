<template>
    <HomeCard class="!min-w-[300px]" :show-arrow="!review || review.closed" @click="onCardClick()">
        <div class="flex flex-col gap-4 w-full sm:h-auto">
            <h3 class="uppercase text-sm text-valence-grey-400 font-semibold tracking-wide mb-1 whitespace-nowrap font-sans" v-text="headingText"></h3>
            <div class="flex flex-col justify-between gap-20">
                <template v-if="review">
                    <div class="flex flex-row justify-between gap-2">
                        <div class="flex flex-col gap-1">
                            <div class="text-5xl font-extralight" v-text="review.responses_count"></div>
                            <div class="uppercase text-sm font-semibold tracking-wide">Responses</div>
                        </div>
                        <div v-if="!review.closed" class="flex flex-col gap-1">
                            <div class="text-5xl font-extralight" v-text="review.missing_responses"></div>
                            <div class="uppercase text-sm font-semibold tracking-wide">More needed</div>
                        </div>
                    </div>
                    <div v-if="review && !review.closed" class="flex flex-col gap-4">
                        <p class="text-left font-medium">
                            <i :class="copied ? 'bi-clipboard-check' : 'bi-clipboard'" class="bi h-4 w-4 mr-2"></i><span v-text="copied ? 'Copied' : 'Copy Invite Link'"></span>
                        </p>
                        <!-- this is the only case where there are 2 possible actions from the card, left close review as a button -->
                        <button
                            v-if="review.missing_responses <= 0"
                            class="rounded-full text-white bg-[#555BA2] hover:bg-[#555BA2]/80 font-bold text-sm py-3 px-16 leading-6 self-center"
                            @click.stop="closeReview"
                        >
                            Close Review
                        </button>
                    </div>
                    <div v-if="review && review.closed">
                        <h3 class="md:text-2xl font-semibold tracking-tighter">Explore feedback</h3>
                    </div>
                </template>
                <template v-else>
                    <h3 class="md:text-2xl font-semibold tracking-tighter">Get started with a 360 review</h3>
                </template>
            </div>
        </div>
    </HomeCard>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { router } from "@inertiajs/vue3";
import { computed, nextTick, ref } from "vue";

import HomeCard from "./HomeCard.vue";

const props = defineProps({
    review: {
        type: Object,
        default: () => null,
    },
});

const copied = ref(false);
const headingText = computed(() => {
    if (props.review && props.review.closed) {
        return "Review feedback";
    }

    return "Collect feedback";
});

function createReview() {
    router.post(
        "/reviews/",
        {},
        {
            only: ["review"],
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
        },
    );
}

function closeReview() {
    router.post(
        `/reviews/${props.review.hashid}/close`,
        {},
        {
            only: ["review"],
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
        },
    );
}

function onCardClick() {
    if (props.review) {
        if (!props.review.closed) {
            copyReviewResponseUrl();
            copied.value = true;
        } else {
            router.visit(props.review.review_coaching_session_url);
        }
    } else {
        createReview();
    }
}

async function copyReviewResponseUrl() {
    const url = new URL(props.review.review_response_url, location.origin);
    await navigator.clipboard.writeText(url.href);
    copied.value = true;
    nextTick(() => {
        setTimeout(() => (copied.value = false), 3000);
    });
}
</script>
