<template>
    <HomeCard @click="handleClick">
        <div class="flex flex-col justify-between gap-6">
            <span class="text-[#8C8C8C] font-semibold">{{ estimatedTime }} min</span>
            <span class="md:text-2xl font-semibold tracking-tight">{{ title }}</span>
        </div>
    </HomeCard>
</template>

<script setup>
import { openUrl } from "~vue/utils";
import { logUserInteraction } from "~vue/utils/logUtils";
import { defineProps } from "vue";

import HomeCard from "./HomeCard.vue";

const props = defineProps({
    isTrending: Boolean,
    isSaved: Boolean,
    title: {
        type: String,
        default: "",
    },
    url: {
        type: String,
        default: "",
    },
    estimatedTime: {
        type: Number,
        default: 5,
    },
});

function handleClick() {
    logUserInteraction("suggested_topics_clicked", { title: props.title });
    if (props.url) {
        openUrl(props.url);
    }
}
</script>

<style scoped>
.iconBold::before {
    /* overwrites bootstrap icon font weight */
    font-weight: bold !important;
}
</style>
