<template>
    <div ref="outerContainer" class="overflow-x-auto px-6 md:px-0 relative">
        <div ref="innerContainer" v-bind="$attrs" class="w-fit md:w-auto border-2 rounded-3xl p-6 md:px-10 md:py-6">
            <span class="md:block md:left-auto md:static" :class="{ 'inline-flex sticky left-[26px]': isStickyTitle }" :style="{ width: stickyTitleDynamicWidth }">
                <slot name="title" :title="title">
                    <h3 class="md:text-2xl font-semibold mb-3 tracking-tight">{{ title }}</h3>
                </slot>
            </span>
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { refDebounced, useMediaQuery, useWindowSize } from "@vueuse/core";
import { computed, onMounted, ref, useTemplateRef } from "vue";

defineOptions({
    inheritAttrs: false,
});
const { isStickyTitle } = defineProps({
    title: { type: String, required: true },
    isStickyTitle: { type: Boolean, default: true },
});

const innerContainer = useTemplateRef("innerContainer");
const outerContainer = useTemplateRef("outerContainer");
const containerPadding = ref(0);

onMounted(() => {
    if (!innerContainer.value || !outerContainer.value) {
        return;
    }

    // dynamically calculates the padding of the inner and outer container
    const innerStyles = window.getComputedStyle(innerContainer.value);
    const outerStyles = window.getComputedStyle(outerContainer.value);

    const totalSpace =
        parseInt(innerStyles.paddingLeft) +
        parseInt(innerStyles.paddingRight) +
        2 * parseInt(innerStyles.borderWidth) +
        parseInt(outerStyles.paddingLeft) +
        parseInt(outerStyles.paddingRight) +
        2 * parseInt(outerStyles.borderWidth);

    containerPadding.value = totalSpace;
});

const { width } = useWindowSize();
// debounces the width to prevent stickyTitleDynamicWidth from being recomputed every pixel
const debouncedWidth = refDebounced(width, 300);
const isMdBreakpoint = useMediaQuery("(min-width: 768px)");
const stickyTitleDynamicWidth = computed(() => (isStickyTitle && !isMdBreakpoint.value ? `${debouncedWidth.value - containerPadding.value}px` : undefined));
</script>
