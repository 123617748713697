<!-- NOTE: this template is essentially the same as GlobalNoticeBanner, when we develop a design system component this should be consolidated -->
<template>
    <div v-if="!dismissed && !!message" role="alert" class="rounded-xl py-4 px-6 bg-[#F6E9FA] border-2 border-[#E5BCEF] flex justify-between items-center">
        <MarkdownText class="text-sm text-[#722ED1] font-semibold" :text="message" />
        <i class="bi bi-x cursor-pointer text-2xl text-[#722ED1]" @click="dismissed = true"></i>
    </div>
</template>

<script setup>
import MarkdownText from "~vue/MarkdownText";
import { ref } from "vue";

defineProps({
    message: {
        type: String,
        default: () => "",
    },
});

const dismissed = ref(false);
</script>
